"use client";

import { useEffect, useState } from "react";
import styles from "./ChatBot.module.scss";
import If from "../../molecules/If/If";

declare global {
	interface Window {
		bmHide?: () => void;
		bmShow?: () => void;
		bmInfo?: () => void;
	}
}

export default function Chatbot({ isSection = false }: any) {
	const [isChatbotVisible, setIsChatbotVisible] = useState(true);

	useEffect(() => {
		const addClassAndButtonToChatbotDiv = () => {
			const chatbotDiv = document.querySelector(
				'div[style*="position: fixed"][style*="bottom: 0px"][style*="right: 0%"]'
			);

			const chatbotIframe = document.getElementById("botmakerMain");
			if (chatbotDiv) {
				chatbotDiv.classList.add("chatbot-div");

				const button = document.createElement("button");
				button.innerText = "X";
				button.style.position = "absolute";
				button.style.top = "5px";
				button.style.right = "5px";
				button.style.zIndex = "2147483648";
				button.style.backgroundColor = "red";
				button.style.color = "white";
				button.style.border = "none";
				button.style.borderRadius = "50%";
				button.style.width = "20px";
				button.style.height = "20px";
				button.style.display = "flex";
				button.style.alignItems = "center";
				button.style.justifyContent = "center";
				button.style.cursor = "pointer";

				button.onclick = () => {
					if (window.bmHide) window.bmHide();
					setIsChatbotVisible(false);
				};

				chatbotDiv.appendChild(button);

				return true;
			}
			return false;
		};

		if (!document.getElementById("chatbot-script")) {
			localStorage.removeItem("@botmaker-chat:maximized");
			const script = document.createElement("script");
			script.type = "text/javascript";
			script.async = true;
			script.src = "https://go.botmaker.com/rest/webchat/p/UGN3SFLFDI/init.js";
			script.id = "chatbot-script";

			script.onload = () => {
				if (!addClassAndButtonToChatbotDiv()) {
					const intervalId = setInterval(() => {
						if (addClassAndButtonToChatbotDiv()) {
							clearInterval(intervalId);
						}
					}, 100);
				}
			};
			document.body.appendChild(script);
		}
	}, []);

	return (
		<If condition={!isChatbotVisible}>
			<button
				className={styles.chatbot_lateral}
				onClick={() => {
					if (window.bmShow) window.bmShow();
					setIsChatbotVisible(true);
				}}
			>
				{"<"}
			</button>
		</If>
	);
}
